import * as React from "react";
import { Link } from "gatsby";
import { Helmet } from "react-helmet";
import { StaticImage } from "gatsby-plugin-image";
import { Layout, Grid } from "../components/layout";
/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import styled from "@emotion/styled";
import { Waypoint } from "react-waypoint";
import { screen, colors } from "../scripts/cssVars";
import { fontSize } from "../scripts/cssFunctions";

const Section = styled.section`
  margin: 4rem 0 8rem;
  ${screen.smaller.tablet} {
    margin: 4rem 0;
  }
`;

const FeaturedServices = styled.section`
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  gap: 1rem 2rem;
  line-height: 1.4;
  ${screen.smaller.tablet} {
    grid-template-columns: minmax(0, 1fr);
  }
  section {
    position: relative;
    &::before {
      content: "";
      display: block;
      width: 54px;
      height: 54px;
      border-radius: 50%;
      background-color: ${colors.electricBlue};
      position: absolute;
      top: -20px;
      left: -20px;
      z-index: -1;
    }
    &:nth-of-type(1)::before {
      background-color: ${colors.pink};
    }
    &:nth-of-type(3)::before {
      background-color: ${colors.mintGreen};
    }
  }
`;

const Highlight = styled.span`
  color: ${colors.primary};
`;

const PortfolioCard = styled.div`
  background: #fff;
  padding: 1.5rem 2rem;
  border-radius: 4px;
  border-left: 4px solid
    ${(props) => (props.color ? props.color : colors.primary)};
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06);
  display: flex;
  justify-content: center;
  flex-flow: column wrap;
  .card-title {
    font-size: ${fontSize(18)};
    margin-bottom: 8px;
  }
  p:last-of-type {
    margin-bottom: 0;
  }
`;

const HireCard = ({ children, position }) => {
  return (
    <a
      href={`mailto:hello@onlineonly.com?subject=Applying as: ${position}`}
      css={css`
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-flow: row wrap;
        background: #fff;
        padding: 1.5rem 2rem;
        margin-bottom: 1rem;
        border-radius: 4px;
        border: none;
        box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1),
          0px 1px 2px rgba(0, 0, 0, 0.06);
        transition: 0.4s all ease-out;
        .card-title {
          color: ${colors.textMain};
          transition: 0.3s all ease-out;
        }
        .card-cta {
          text-transform: uppercase;
          color: ${colors.primary};
          transition: 0.3s all ease-out;
        }
        &:hover,
        &:focus,
        &:active {
          box-shadow: 0px 8px 20px -4px rgba(0, 0, 0, 0.15),
            0px 0px 4px rgba(0, 0, 0, 0.04);
          transform: scale(1.025);
          transition: 0.15s all ease-in-out;
          z-index: 2;
          .card-title {
            color: ${colors.primaryHover};
          }
          .card-cta {
            color: ${colors.primaryHover};
          }
        }
      `}
    >
      <div className="card-title">
        <strong>{position}</strong> {children}
      </div>
      <div className="card-cta">
        <strong>Apply Now</strong>
      </div>
    </a>
  );
};

/**
 * TODO: Scrollspy
 */

// markup
const IndexPage = () => {
  const [activeSpy, setActiveSpy] = React.useState("home");
  return (
    <Layout
      seoTitle="ONLINE ONLY | Digital Marketing Agency"
      activeSpy={activeSpy}
    >
      <Helmet title="ONLINE ONLY | Digital Marketing Agency" />
      <Grid
        css={css`
          gap: 2rem 4rem;
          align-items: center;
        `}
      >
        <div className="hero-content">
          <h1
            css={css`
              max-width: 465px;
            `}
          >
            We remotely manage profitable online businesses
            <Highlight>.</Highlight>
          </h1>
          <p>
            <strong>ONLINE ONLY</strong> is a fully remote team of ~30 persons
            who operate and manage profitable online businesses.
          </p>
          <p>
            Our portfolio includes SaaS, eCommerce and online publishing
            businesses that are mostly co-owned by the founders.
          </p>
          <p>
            Our marketing specialities are Conversion Optimization (CRO), Google
            Ads (PPC), Search Engine Optimisation (SEO), Design and Development.
          </p>
        </div>
        <div className="hero-graphic">
          <StaticImage
            src="../images/hero-abstract@2x.png"
            width="480"
            height="360"
            alt="Abstract graphic"
            placeholder="tracedSVG"
            css={css`
              float: right;
              ${screen.smaller.tablet} {
                display: none;
              }
            `}
          />
        </div>
      </Grid>

      <Section id="featured-services">
        <FeaturedServices>
          <section>
            <h3>Our Investments</h3>
            <p>We invest in SaaS, eCommerce and publishing businesses.</p>
            <p>
              <Link to="#our-portfolio">See our portfolio</Link>
            </p>
          </section>
          <section>
            <h3>What We Do</h3>
            <p>
              We buy a stake of your business and help you get more paying
              clients online.{" "}
            </p>
          </section>
          <section>
            <h3>Sell % of Your Business</h3>
            <p>
              Do you run a lifestyle internet business that is profitable?
              Awesome. Let’s talk!
            </p>
            <p>
              <a href="mailto:hello@onlineonly.com">Send us an email</a>
            </p>
          </section>
        </FeaturedServices>
      </Section>

      <Waypoint
        onEnter={() => {
          setActiveSpy("portfolio");
        }}
        onLeave={({ waypointTop }) => {
          if (waypointTop > 0) {
            setActiveSpy("home");
          }
        }}
        topOffset="20%"
        bottomOffset="80%"
      >
        <Section id="our-portfolio">
          <h2>Our Portfolio</h2>
          <p>Latest investments and M&amp;As</p>
          <div
            className="portfolio-grid"
            css={css`
              display: grid;
              grid-template-columns: repeat(2, minmax(0, 1fr));
              gap: 2rem;
              align-items: stretch;
              margin-top: 1.5rem;
              ${screen.smaller.mobile} {
                grid-template-columns: minmax(0, 1fr);
              }
            `}
          >
            <PortfolioCard color={colors.pink}>
              <h3 className="card-title">Patchstack.com (SaaS)</h3>
              <p>Launched in 2018, security software for WordPress websites</p>
            </PortfolioCard>
            <PortfolioCard color={colors.mintGreen}>
              <h3 className="card-title">Swiftify.com (SaaS)</h3>
              <p>
                Launched in 2014, Objective-C to Swift converter. Convert code,
                files and projects online or via desktop app.
              </p>
            </PortfolioCard>
            <PortfolioCard color={colors.primary}>
              <h3 className="card-title">Pressjitsu.com (SaaS)</h3>
              <p>
                Launched in 2014, managed WordPress hosting provider, powered by
                AWS &amp; Cloudfront.
              </p>
            </PortfolioCard>
            <PortfolioCard color={colors.electricBlue}>
              <h3 className="card-title">Fixrunner.com (SaaS)</h3>
              <p>
                Launched in 2014, helping small business owners with WordPress
                maintenance and development.
              </p>
            </PortfolioCard>
            <PortfolioCard color={colors.textMain}>
              <h3 className="card-title">PHPjabbers.com (eCommerce)</h3>
              <p>
                Launched in 2006, top-rated PHP scripts for web developers and
                different webmasters.
              </p>
            </PortfolioCard>
            <PortfolioCard color={colors.textMain}>
              <h3 className="card-title">
                GlobalPropertyGuide.com (Publishing)
              </h3>
              <p>
                Launched in 2004, the most prominent global real estate news and
                magazine site that covers 150+ countries data.
              </p>
            </PortfolioCard>
          </div>
        </Section>
      </Waypoint>

    </Layout>
  );
};

export default IndexPage;
