import "@fontsource/poppins/700.css";

export const colors = {
  primary: "#0072ce",
  primaryHover: "#005396",
  secondary: "#444649",
  secondaryHover: "#666",
  textMain: "#444649",
  pink: "#E799FF",
  mintGreen: "#36FFDB",
  electricBlue: "#27EAFF",
};

export const screen = {
  bigger: {
    mobile: "@media screen and (min-width: 481px)",
    tablet: "@media screen and (min-width: 1025px)",
    desktop: "@media screen and (min-width: 1201px)",
  },
  smaller: {
    mobile: "@media screen and (max-width: 480px)",
    tablet: "@media screen and (max-width: 1024px)",
    desktop: "@media screen and (max-width: 1200px)",
  },
};

export const headings = "h1, h2, h3, h4, h5, h6";

export const fonts = {
  sans: `-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;`,
  sansHead: `"Poppins", "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;`,
  serif: `"Merriweather", "Lora", Constantia, "Lucida Bright", Lucidabright, "Lucida Serif", Lucida, "DejaVu Serif", "Bitstream Vera Serif", "Liberation Serif", Georgia, serif;`,
  serifAlt: `"Lora", "Palatino Linotype", Palatino, Palladio, "URW Palladio L", "Book Antiqua", Baskerville, "Bookman Old Style", "Bitstream Charter", "Nimbus Roman No9 L", Garamond, "Apple Garamond", "ITC Garamond Narrow", "New Century Schoolbook", "Century Schoolbook", "Century Schoolbook L", Georgia, serif;`,
  code: `monaco, consolas, "Andale Mono", "DejaVu Sans Mono", monospace;`,
  pre: `"Courier 10 Pitch", courier, monospace;`,
};
