import { css } from "@emotion/react";

export const burgerMenuCSS = css`
  .bm-cross-button {
    height: 24px;
    width: 24px;
  }
  .bm-cross {
    background: #bdc3c7;
  }
  .bm-menu-wrap {
    position: fixed;
    height: 100%;
  }
  .bm-menu {
    background: #373a47;
    padding: 2.5em 1.5em 0;
    font-size: 1.15em;
  }
  .bm-morph-shape {
    fill: #373a47;
  }
  .bm-item-list {
    color: #b8b7ad;
    padding: 0.8em;
  }
  .bm-item {
    display: inline-block;
    list-style: none;
    padding: 0;
    margin: 0;
  }
  .bm-item a {
    color: #fff;
    font-weight: 700;
    text-decoration: none;
    padding: 0.5rem 0;
    display: block;
  }
  .bm-overlay {
    background: rgba(0, 0, 0, 0.3);
  }
  .hamburger-react {
    float: right;
    transform: translateY(-5px);
  }
`;
